<template>
  <div>
    <Header />
    <div class="main_wrap">
      <slot />
      <Footer />
    </div>
    <SharePopup />
  </div>
</template>

<script setup>
import { useHeaderStore } from '@/stores/header'
import { onMounted, onBeforeUnmount, watch } from 'vue'

const store = useHeaderStore();
const { locale } = useI18n();
const { $addBodyClassByi18n } = useNuxtApp();

watch(locale, (newVal, oldVal) => {
  $addBodyClassByi18n(newVal, oldVal);
})

onMounted(() => {
  $addBodyClassByi18n(locale.value, '');
  store.showGnb(false);
  window.addEventListener('resize', appHeight);
  appHeight();
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', appHeight);
})

function appHeight() {
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', `${window.innerHeight}px`);
}
</script>

<style lang="scss" scoped>
:deep(.main_wrap main::before) {
  display: none !important;
}
</style>